<template>

  <div>
    <b-card
      class="mb-0"
    >
      <div class="mb-2">
        <b-row>
          <b-col
            cols="12"
            md="5"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <h4 class="card-title mb-0 pt-50">
              Timesheet Management System</h4>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              v-show="employers.length > 1"
              label="Parent Type"
              label-cols-md="3"
              class="text-right mb-0"
            >
              <v-select
                v-model="parent"
                :options="employers"
                :reduce="item => item"
                label="parent_type"
                :clearable="false"
                placeholder="Select"
                @input="onTypeChanged()"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="3"
            class="text-right"
          >
            <b-button
              v-show="allow_timesheets"
              variant="primary"
              :to="{ name: 'timesheets-new'}"
            >
              <span class="text-nowrap">Add Timesheet</span>
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="6 ml-auto"
            class="col-xxl-4"
          >
            <b-alert
              v-if="showRejected"
              variant="warning"
              show
              class="mt-1 mb-0"
            >
              <div class="alert-body">
                <span>
                  <feather-icon
                    icon="AlertCircleIcon"
                    size="16"
                  />
                  You have a rejected timesheet, please click
                  <feather-icon
                    icon="EditIcon"
                    size="16"
                  />
                  to make corrections.
                </span>
              </div>
            </b-alert>
          </b-col>
        </b-row>

      </div>

      <vue-good-table
        :columns="columns"
        :rows="timesheetsResponse.time_sheets"
        :rtl="direction"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >

          <!-- Column: Status -->
          <span v-if="props.column.field === 'status'">
            <b-badge :variant="statusVariant(props.row.status)">
              {{ props.row.status }}
            </b-badge>
          </span>

          <!-- Column: Action -->
          <span
            v-else-if="props.column.field === 'action'"
            class="btn-action"
          >
            <span>
              <b-link
                id="view"
                class="ml-1"
                :to="{ name: 'timesheets-view', params: { id: props.row.id } }"
              >
                <b-badge
                  pill
                  variant="light-primary"
                ><feather-icon icon="EyeIcon" />
                </b-badge>
              </b-link>
              <b-link
                v-if="props.row.editable && allow_timesheets"
                id="Edit"
                class="ml-2"
                :to="{ name: 'timesheets-edit', params: { id: props.row.id } }"
              >
                <b-badge
                  pill
                  variant="light-warning"
                ><feather-icon icon="Edit2Icon" />
                </b-badge>
              </b-link>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
        >
          <div class="d-flex justify-content-end flex-wrap">
            <div>
              <b-pagination
                v-model="currentPage"
                :total-rows="timesheetsResponse.count"
                first-number
                last-number
                align="right"
                :per-page="1"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BLink, BBadge, BPagination, BFormGroup, BAlert,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import store from '@/store/index'
import User from '@/js/user'
import Timesheets from '@/js/timesheets'
import Auth from '@/auth/authService'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BLink,
    BBadge,
    BPagination,
    BFormGroup,
    BAlert,
    VueGoodTable,

    vSelect,
  },
  data() {
    return {
      pageLength: 10,
      dir: false,
      type: null,
      showRejected: false,
      parent_id: null,
      parent: null,
      employers: [],
      timesheetsResponse: {
        timesheets: [],
        count: 1,
      },
      columns: [
        {
          label: '#',
          field: 'index',
          width: '100px',
          sortable: false,
        },
        {
          label: 'Month / Week',
          field: 'week_start_date',
          width: '250px',
          sortable: false,
        },
        {
          label: 'Employee / Consultant Name',
          field: 'name',
          sortable: false,
        },
        {
          label: 'Total Hours',
          field: 'total_hours',
          sortable: false,
        },
        {
          label: 'Expenses',
          field: 'expenses',
          sortable: false,
        },
        {
          label: 'Status',
          field: 'status',
          width: '150px',
          sortable: false,
        },
        {
          label: 'Action',
          field: 'action',
          width: '120px',
          sortable: false,
        },
      ],
      rows: [],
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.$store.state.query.page
      },
      set(val) {
        this.$store.state.query.page = val
        Timesheets.getTimesheets(this)
      },
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Approved      : 'light-success',
        Submitted     : 'light-primary',
        Rejected      : 'light-danger',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.searchQuery = this.$store.state.query.query
    this.timesheetsResponse.count = this.$store.state.query.page
    this.employers = User.profile().timesheet_provider_parents
    this.allow_timesheets = User.profile().allow_timesheet_parents.length > 0
    if (this.employers.length >= 1) {
      [this.parent] = this.employers
      this.type = this.parent.parent_type_id
      this.parentType = this.employers[0].parent_type
      this.$store.state.selectedUser = this.parent
    }
    this.parent = this.$store.state.selectedUser
    if (this.parent !== null) {
      this.type = this.parent.parent_type_id
    }
    if (User.profile().timesheet_provider_parents.length > 0) {
      Timesheets.getTimesheets(this)
    } else if (User.profile().invoice_exist) {
      window.location.hash = '#/invoices'
    } else if (User.profile().job_exist) {
      window.location.hash = '#/jobs'
    } else {
      Auth.logout()
    }
  },
  methods: {
    onTypeChanged() {
      this.timesheets = []
      this.recordsCount = 1
      this.showRejected = false
      this.pageNum = 1
      this.type = this.parent.parent_type_id
      this.$store.state.selectedUser = this.parent
      Timesheets.getTimesheets(this)
    },
    updateSearchQuery() {
      this.$store.state.query.query = this.searchQuery
      this.currentPage = 1
    },
    setTimesheets(timesheetsResponse) {
      this.timesheetsResponse = timesheetsResponse
      this.showRejected = timesheetsResponse.rejected_exist
    },
  },
}
</script>

<style lang="scss" scoped>
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
